<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="nav align-items-center flex-grow-2 d-lg-flex">
      <template>
        <b-nav-item-dropdown
          id="dropdown-grouped"
          variant="link"
          class="dropdown-language"
          left
        >
          <template #button-content>
            <!-- <b-img
              :src="currentLocale.img"
              height="14px"
              width="22px"
              :alt="currentLocale.locale"
            /> -->
            <span class="ml-50 text-body">{{ currentLocale.name }}</span>
          </template>
          <b-dropdown-item
            v-for="localeObj in locales"
            :key="localeObj.locale"
            @click="setLanguage(localeObj.locale)"
          >
            <!-- <b-img
              :src="localeObj.img"
              height="14px"
              width="22px"
              :alt="localeObj.locale"
            /> -->
            <span class="ml-50">{{ localeObj.name }}</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </template>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0"></p>
            <span v-if="user" class="user-status"
              >{{ user.first_name }} {{ user.last_name }}</span
            >
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            v-if="user && user.profile_image"
            :src="user.profile_image"
            class="badge-minimal"
            badge-variant="success"
          />
          <b-avatar
            size="40"
            v-else
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/undefined.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          v-if="$can('settings.list')"
          @click="openSettings"
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span> {{ $t("Settings") }} </span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
            @click="logout"
          />
          <span>{{ $t("Logout") }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BImg,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BImg,
  },

  async beforeCreate() {
    this.$store.dispatch("auth/show", {
      id: localStorage.getItem("user_id"),
    });
  },

  methods: {
    setLanguage(param) {
      this.$i18n.locale = param;
      localStorage.setItem("lang", param);
    },
    openSettings() {
      this.$router.push("/settings");
    },
    async logout() {
      localStorage.clear();
      localStorage.setItem("user", "{}");
      localStorage.setItem("token", "");
      localStorage.setItem("refresh_token", "");
      localStorage.setItem("authenticated", false);
      localStorage.setItem("userProfileImage", "");
      this.$store.commit("permissions/permissions", []);
      this.$store.commit("auth/set_user", {});
      this.$store.commit("auth/userPermissions", []);
      this.$store.commit("permissions/permissionsGlobal", []);

      this.$router.push("/");
    },
  },

  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    ...mapGetters("auth", ["user"]),
    currentLocale() {
      return this.locales.find((l) => l.locale === this.$i18n.locale);
    },
  },
  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: "ar",
        img: require("@/assets/images/flags/ar.png"),
        name: "Arabic",
      },
      {
        locale: "ch",
        img: require("@/assets/images/flags/ch.jpg"),
        name: "Chinese",
      },
      {
        locale: "en",
        img: require("@/assets/images/flags/en.png"),
        name: "English",
      },
      {
        locale: "fr",
        img: require("@/assets/images/flags/fr.png"),
        name: "French",
      },
      {
        locale: "de",
        img: require("@/assets/images/flags/de.png"),
        name: "German",
      },
      {
        locale: "ja",
        img: require("@/assets/images/flags/ja.png"),
        name: "Japanese",
      },
      {
        locale: "es",
        img: require("@/assets/images/flags/es.png"),
        name: "Spanish",
      },
      {
        locale: "tu",
        img: require("@/assets/images/flags/tu.png"),
        name: "Turkish",
      },
    ];
    /* eslint-disable global-require */

    return {
      locales,
    };
  },
};
</script>
