<template>
  <div>
    <PageHeader :items="items" />
    <validation-observer ref="simpleRules">
      <div class="card">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-md-6">
              <validation-provider
                #default="{ errors }"
                name="Invoice Type"
                rules="required"
              >
                <label class="input-label form-label"
                  ><span style="color: red">*</span>&nbsp;{{
                    $t("Invoice Type")
                  }}
                </label>
                <multiselect
                  :disabled="invoiceStatus != 'draft'"
                  v-model="form.invoiceType"
                  :key="form.invoiceType"
                  :options="['invoice-correction', 'invoice', 'invoice-storno']"
                  :multiple="false"
                >
                </multiselect>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-md-6">
              <validation-provider
                #default="{ errors }"
                name="Status"
                rules="required"
              >
                <label class="input-label form-label"
                  ><span style="color: red">*</span>&nbsp;{{ $t("Status") }}
                </label>
                <multiselect
                  v-model="form.status"
                  :key="form.status"
                  :options="statusOptions"
                  :multiple="false"
                >
                </multiselect>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-6">
              <validation-provider
                #default="{ errors }"
                name="Due Date"
                rules="required"
              >
              <DatePicker v-model="form.dueDate" :label="$t('Due Date')" :required="true" :isLeftIcon="true" :leftIconName="'dateIcon'"/>
                <!-- <TextInput
                  v-model="form.dueDate"
                  type="date"
                  :disabled="invoiceStatus != 'draft'"
                  :label="$t('Due Date')"
                  :tooltipMessage="$t('Due Date')"
                  :required="true"
                  :key="form.dueDate"
                /> -->
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="input-label form-label"
                  ><span style="color: red">*</span>&nbsp;{{ $t("Customer") }}
                </label>
                <validation-provider
                  #default="{ errors }"
                  name="Customer"
                  rules="required"
                >
                  <MultiSelectInput
                    v-model="form.companyId"
                    :options="customers"
                    label="companyName"
                    :isDisabled="invoiceStatus != 'draft'"
                    trackBy="id"
                    :key="form.companyId"
                    moduleName="customers"
                    :multiple="false"
                  >
                  </MultiSelectInput>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-md-6">
              <validation-provider
                #default="{ errors }"
                name="Start Date"
                rules="required"
              >
              <DatePicker v-model="form.startDate" :label="$t('Start Date')" :required="true" :isLeftIcon="true" :leftIconName="'dateIcon'"/>
                <!-- <TextInput
                  v-model="form.startDate"
                  type="date"
                  :disabled="invoiceStatus != 'draft'"
                  :label="$t('Start Date')"
                  :tooltipMessage="$t('Start Date')"
                  :required="true"
                  :key="form.startDate"
                /> -->
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-md-6">
              <validation-provider
                #default="{ errors }"
                name="End Date"
                rules="required"
              >
              <DatePicker v-model="form.endDate" :label="$t('End Date')" :required="true" :isLeftIcon="true" :leftIconName="'dateIcon'"/>
                <!-- <TextInput
                  v-model="form.endDate"
                  type="date"
                  :disabled="invoiceStatus != 'draft'"
                  :label="$t('End Date')"
                  :tooltipMessage="$t('End Date')"
                  :required="true"
                  :key="form.endDate"
                /> -->
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-6">
              <!-- <TextInput
                v-model="form.invoiceDate"
                type="date"
                :disabled="invoiceStatus != 'draft'"
                :label="$t('Invoice Date')"
                :tooltipMessage="$t('Invoice Date')"
                :key="form.invoiceDate"
              /> -->
              <DatePicker v-model="form.invoiceDate" :label="$t('Invoice Date')" :isLeftIcon="true" :leftIconName="'dateIcon'"/>
            </div>
            <div class="col-md-6">
              <TextInput
                v-model="form.externalOrderNumber"
                type="text"
                :disabled="invoiceStatus != 'draft'"
                :label="$t('External Order Number')"
              />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-6">
              <label for="cars">{{ $t("Apply Reverse Charge") }}</label>

              <input
                id="active"
                :disabled="invoiceStatus != 'draft'"
                class="form-check-input-custom ml-2"
                v-model="form.applyReverseCharge"
                type="checkbox"
              />
            </div>
            <div v-if="form.invoiceType == 'invoice-storno'" class="col-md-6">
              <label for="cars">{{ $t("Reference Invoice") }}</label>
              <MultiSelectInput
                v-model="referenceInvoiceDetail"
                :options="referenceInvoices"
                label="invoiceNumber"
                trackBy="id"
                :isDisabled="invoiceStatus != 'draft'"
                :key="referenceInvoiceDetail"
                moduleName="invoices"
                :multiple="false"
              >
              </MultiSelectInput>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label for="cars">{{ $t("Custom Notes Field") }}</label>
              <textarea
                :disabled="invoiceStatus != 'draft'"
                class="form-control"
                rows="5"
                v-model="form.customNotesFields"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="card invoice-details">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <h2>{{ $t(form.status) }}</h2>
          <h2>{{ form.startDate }}</h2>
        </div>
        <div class="card-body px-3">
          <div class="row mt-2 align-items-start">
            <div class="col-lg-4 col-md-4 col-12">
              <div class="d-flex align-items-center justify-content-between">
                <h4>{{ $t("Customer") }}</h4>
                <p v-if="form.companyId.companyName">
                  {{ form.companyId.companyName }}
                </p>
                <p v-else>-</p>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <h4>{{ $t("Invoice Nr.") }}</h4>
                <p v-if="form.invoiceNumber">
                  {{ form.invoiceNumber }}
                </p>
                <p v-else>-</p>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
              <div class="d-flex align-items-center justify-content-between">
                <h4>{{ $t("Created By") }}:</h4>
                <p>{{ user.firstName }} {{ user.lastName }}</p>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <h4>{{ $t("Due Date") }}:</h4>
                <p>{{ form.dueDate }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" aria-label="Products Header">
          <h3 class="card-title">
            {{ $t("Products") }}
          </h3>
        </div>
        <div class="card-body px-3" aria-label="Products Body">
          <div class="w-100">
            <div
              v-for="(products, index) in form.products"
              :key="index"
              class="mb-2"
            >
              <div class="row align-items-start">
                <div class="col-md-1">
                  <validation-provider
                    #default="{ errors }"
                    name="POS"
                    rules="required"
                  >
                    <text-input
                      v-model="products.pos"
                      :label="$t('POS')"
                      :required="true"
                      :disabled="invoiceStatus != 'draft'"
                      :maxLength="100"
                      aria-label="POS Input"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
                <div class="col-md-2">
                  <text-input
                    v-model="products.articleNumber"
                    :label="$t('Article Number')"
                    :disabled="invoiceStatus != 'draft'"
                    :maxLength="100"
                    aria-label="Article Number Input"
                  />
                </div>
                <div class="col-md-2">
                  <validation-provider
                    #default="{ errors }"
                    name="Product Name"
                    rules="required"
                  >
                    <text-input
                      v-model="products.productName"
                      :label="$t('Product Name')"
                      :required="true"
                      :disabled="invoiceStatus != 'draft'"
                      :maxLength="100"
                      aria-label="Product Name Input"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>

                <div class="col-md-1">
                  <validation-provider
                    #default="{ errors }"
                    name="Quantity"
                    rules="required"
                  >
                    <text-input
                      v-model="products.quantity"
                      :label="$t('Quantity')"
                      :required="true"
                      :disabled="invoiceStatus != 'draft'"
                      type="number"
                      :maxLength="100"
                      @input="quantityChanged($event, index)"
                      aria-label="Quantity Input"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
                <div class="col-md-1">
                  <validation-provider
                    #default="{ errors }"
                    name="Tax"
                    rules="required"
                  >
                    <text-input
                      v-model="products.tax"
                      :label="$t('Tax')"
                      :required="true"
                      :disabled="invoiceStatus != 'draft'"
                      type="number"
                      :maxLength="100"
                      aria-label="Tax Input"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
                <div class="col-md-2">
                  <validation-provider
                    #default="{ errors }"
                    name="Product Price"
                    rules="required"
                    class="w-100"
                  >
                    <text-input
                      v-model="products.productPrice"
                      :label="$t('Product Price')"
                      :required="true"
                      :maxLength="100"
                      :disabled="invoiceStatus != 'draft'"
                      type="number"
                      @input="priceChanged($event, index)"
                      aria-label="Product Price Input"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
                <div class="col-md-3 d-flex align-items-center gap-2">
                  <div class="w-100">
                    <label class="input-label form-label"
                      ><span style="color: red">*</span>&nbsp;{{
                        $t("Netto Total")
                      }}
                    </label>
                    <div class="form-control">
                      <div>
                        {{
                          $formatter(
                            products.nettoTotal,
                            $i18n.locale,
                            "EUR",
                            false,
                            2,
                            2
                          )
                        }}
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="invoiceStatus == 'draft'"
                    style="margin-top: 8px"
                    role="button"
                    @click="deleteProducts(index)"
                    aria-label="Delete Products button"
                  >
                    <feather-icon icon="XIcon" size="30" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="form.products.length > 0"
            class="d-flex align-items-center justify-content-end mt-2"
          >
            <div class="product-total">
              <ul>
                <li>
                  <h3>{{ $t("Netto") }}</h3>
                  <p>
                    {{
                      $formatter(totalNetto, $i18n.locale, "EUR", false, 2, 2)
                    }}
                  </p>
                </li>
                <div v-if="!form.applyReverseCharge">
                  <li v-for="(tax, index) in calculateTax" :key="index">
                    <h3>Tax {{ tax.percent }}%</h3>
                    <p>
                      {{
                        $formatter(tax.amount, $i18n.locale, "EUR", false, 2, 2)
                      }}
                    </p>
                  </li>
                </div>
                <li class="total">
                  <h3>{{ $t("Total") }}</h3>
                  <p>
                    {{
                      $formatter(totalAmount, $i18n.locale, "EUR", false, 2, 2)
                    }}
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <b-button
            v-if="invoiceStatus == 'draft'"
            variant="primary"
            @click="addProducts"
            class="mt-2"
          >
            <feather-icon :icon="'PlusIcon'" size="16" />
            <span>
              {{ $t("Add products") }}
            </span>
          </b-button>
        </div>
      </div>

      <div class="d-flex align-items-center justify-content-end">
        <b-button
          v-if="form.invoiceType === 'invoice' && invoiceStatus !== 'draft'"
          :disabled="isLoading"
          variant="warning"
          @click="cancelInvoice"
          class="d-flex align-items-center gap-1 mr-2"
        >
          <feather-icon icon="PlusIcon" size="12" />
          <span>{{ $t("Cancel Invoice") }}</span>
        </b-button>
        <b-button
          :disabled="isLoading"
          variant="success"
          @click="copyInvoice"
          class="d-flex align-items-center gap-1 mr-2"
        >
          <feather-icon icon="PlusIcon" size="12" />
          <span>{{ $t("Copy Invoice") }}</span>
        </b-button>
        <b-button
          :disabled="isLoading"
          variant="primary"
          @click="generate('pdf')"
          class="d-flex align-items-center gap-1 mr-2"
        >
          <feather-icon icon="PlusIcon" size="12" />
          <span>{{ $t("Generate PDF") }}</span>
        </b-button>
        <b-button
          :disabled="isLoading"
          variant="primary"
          @click="generate('docx')"
          class="d-flex align-items-center gap-1 mr-2"
        >
          <feather-icon icon="PlusIcon" size="12" />
          <span>{{ $t("Generate Docx") }}</span>
        </b-button>
        <b-button
          v-if="form.status.includes('warning')"
          :disabled="isLoading"
          variant="primary"
          @click="generate('pdf', true)"
          class="d-flex align-items-center gap-1 mr-2"
        >
          <feather-icon icon="PlusIcon" size="12" />
          <span>{{ $t("Generate Warning") }}</span>
        </b-button>

        <b-button
          :disabled="isLoading"
          variant="primary"
          @click="updateInvoice"
          class="d-flex align-items-center gap-1"
        >
          <feather-icon icon="PlusIcon" size="12" />
          <span>{{ $t("Update Invoice") }}</span>
        </b-button>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import TextInput from "@/components/TextInput.vue";
import PageHeader from "@/components/PageHeader.vue";
import Multiselect from "vue-multiselect";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapGetters } from "vuex";
import NotificationService from "@/services/notification.service";
import { required, email } from "@validations";
import MultiSelectInput from "@/components/MultiSelectInput.vue";
import DatePicker from "@/components/DatePicker.vue";
import ElementsIcon from "@/components/elements/Icon.vue";
export default {
  components: {
    TextInput,
    PageHeader,
    ValidationProvider,
    ValidationObserver,
    Multiselect,
    MultiSelectInput,
    DatePicker,
    ElementsIcon
  },
  watch: {
    referenceInvoiceDetail(newVal) {
      if (
        this.form.customNotesFields?.includes(
          "Cancellation invoice to invoice # "
        )
      ) {
        this.form.customNotesFields =
          "Cancellation invoice to invoice # " + newVal?.invoiceNumber;
      }
    },
  },
  computed: {
    ...mapGetters("customers", ["customers"]),
    ...mapGetters("invoices", ["referenceInvoices"]),
    totalNetto() {
      return this.form.products.reduce((sum, product) => {
        return sum + parseFloat(product.nettoTotal || 0);
      }, 0);
    },
    totalAmount() {
      // Sum all the taxes from calculateTax and add totalNetto
      const totalTaxes = this.calculateTax.reduce((sum, tax) => {
        return sum + parseFloat(tax.amount);
      }, 0);

      return parseFloat(this.totalNetto + totalTaxes).toFixed(2);
    },
    calculateTax() {
      if (this.form.applyReverseCharge) {
        // If reverse charge is applied, all amounts should be zero
        return Object.keys(
          this.form.products.reduce((result, product) => {
            result[product.tax] = 0;
            return result;
          }, {})
        ).map((tax) => ({
          percent: tax,
          amount: "0.00",
        }));
      }

      const groupedProducts = this.form.products.reduce((result, product) => {
        const tax = product.tax;
        if (!result[tax]) {
          result[tax] = [];
        }
        result[tax].push(product);
        return result;
      }, {});

      const result = Object.entries(groupedProducts).map(([tax, products]) => ({
        percent: tax,
        amount: products.reduce((sum, product) => {
          const nettoTotal = parseFloat(product.nettoTotal) || 0;
          const taxAmount = (nettoTotal * parseFloat(tax)) / 100;
          return parseFloat(sum + taxAmount).toFixed(2);
        }, 0),
      }));

      return result;
    },
    items() {
      return [
        {
          text: "Dental Twin",
          to: "/home",
        },
        {
          text: this.$t("Invoices"),
          to: "/invoices",
        },
        {
          text: this.$t("Edit"),
          active: true,
        },
      ];
    },
  },
  data() {
    return {
      statusOptions: [],
      reminderLevels: [],
      invoiceStatus: "",
      form: {
        invoiceType: "",
        companyId: "",
        dueDate: "",
        startDate: "",
        endDate: "",
        invoiceDate: "",
        externalOrderNumber: "",
        applyReverseCharge: false,
        products: [],
        status: "",
        invoiceNumber: "",
        customNotesFields: "",
      },
      referenceInvoiceDetail: "",
      isLoading: false,
      user: {
        firstName: "",
        lastName: "",
      },
      modelData: {},
    };
  },
  async created() {
    try {
      this.$store.commit("showLoader", true);
      await this.$store.dispatch("customers/list");
      await this.$store.dispatch("invoices/list", {
        isReference: true,
        isAdminPortal: true,
      });
      this.refresh();
      let reminders = await this.$store.dispatch("invoiceReminderLevel/list");
      this.reminderLevels = reminders?.data?.data;
      const response = await this.$store.dispatch(
        "invoices/documentAssignmentList"
      );
      this.modelData = response?.data?.data ?? {};
    } catch (e) {
      console.error("api error ___", e);
    } finally {
      this.$store.commit("showLoader", false);
    }
  },
  methods: {
    filterStatusOptions(status) {
      const allOptions = [
        "draft",
        "approved",
        "sent",
        "warning level 1",
        "warning level 2",
        "warning level 3",
        "paid",
      ];

      const statusIndex = allOptions.indexOf(status);

      if (statusIndex !== -1) {
        // Update statusOptions to remove all previous statuses
        this.statusOptions = allOptions.slice(statusIndex);
      } else {
        // If the status is not found, keep all options
        this.statusOptions = allOptions;
      }
    },
    async refresh() {
      await this.$store
        .dispatch("invoices/show", this.$route.params.id)
        .then((res) => {
          let data = res?.data?.data;
          this.filterStatusOptions(data.status);
          this.invoiceStatus = data.status;
          this.getUser(data.userId);
          this.form.invoiceType = data.invoiceType;
          this.form.customNotesFields = data.customNotesFields;
          this.form.companyId = data.company;
          this.form.dueDate = data.dueDate;
          this.form.startDate = data.startDate;
          this.form.invoiceNumber = data.invoiceNumber;
          this.form.endDate = data.endDate;
          this.form.invoiceDate = data.invoiceDate;
          this.form.status = data.status;
          this.referenceInvoiceDetail = data.referenceInvoiceDetail;
          this.form.externalOrderNumber = data.externalOrderNumber;
          this.form.applyReverseCharge = data.applyReverseCharge;
          this.form.products = data.products;
        });
    },
    addProducts() {
      this.form.products.push({
        pos: this.form.products.length + 1,
        articleNumber: "",
        productName: "",
        productPrice: "",
        quantity: "",
        nettoTotal: "",
        tax: "",
      });
    },
    getUser(id) {
      this.$store
        .dispatch("users/show", {
          id: id,
        })
        .then(async (res) => {
          if (res.message_type === "error") {
          } else {
            var response = res?.data;

            this.user.firstName = response.first_name;
            this.user.lastName = response.last_name;
          }
        });
    },
    quantityChanged(event, index) {
      this.form.products[index].nettoTotal =
        event * this.form.products[index].productPrice;
    },
    priceChanged(event, index) {
      this.form.products[index].nettoTotal =
        event * this.form.products[index].quantity;
    },
    async deleteProducts(index) {
      this.$swal({
        title: this.$t("Do you want to delete this record?"),
        text: this.$t("You can't revert your action"),
        type: "warning",
        customClass: "custom-delete-popup",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes delete it!"),
        cancelButtonText: this.$t("No"),
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          this.form.products.splice(index, 1);
        }
      });
    },
    async copyInvoice() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          const totalTaxes = this.calculateTax.reduce((sum, tax) => {
            return sum + parseFloat(tax.amount);
          }, 0);
          this.isLoading = true;
          await this.$store
            .dispatch("invoices/create", {
              ...this.form,
              companyId: this.form.companyId?.id ?? "",
              totalAmount: this.totalAmount,
              taxAmount: totalTaxes,
              netto: this.totalNetto,
            })
            .finally(() => {
              this.updateInvoice();
              this.isLoading = false;
            });
        }
      });
    },
    async cancelInvoice() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          const totalTaxes = this.calculateTax.reduce((sum, tax) => {
            return sum + parseFloat(tax.amount);
          }, 0);

          var products = this.form.products.map((product) => {
            return {
              ...product,
              quantity: -1 * parseFloat(product.quantity), // Convert to negative and keep it as string,
              nettoTotal: -1 * parseFloat(product.nettoTotal),
            };
          });
          this.isLoading = true;
          await this.$store
            .dispatch("invoices/create", {
              ...this.form,
              products: products,
              invoiceType: "invoice-storno",
              status: "draft",
              referenceInvoiceId:
                this.referenceInvoiceDetail?.id ?? this.$route.params.id,
              companyId: this.form.companyId?.id ?? "",
              totalAmount: -1 * this.totalAmount,
              taxAmount: -1 * totalTaxes,
              netto: -1 * this.totalNetto,
              customNotesFields:
                "Cancellation invoice to invoice # " +
                (this.referenceInvoiceDetail?.invoiceNumber ??
                  this.form.invoiceNumber),
            })
            .finally(() => {
              this.updateInvoice();
              this.isLoading = false;
            });
        }
      });
    },
    async generate(documentType, isWarning = false) {
      let templateId;
      let warningLevel = {};

      if (this.form.status.startsWith("warning level")) {
        const matchedLevel = this.reminderLevels.find(
          (level) => level.levelName === this.form.status
        );
        if (isWarning == true) {
          if (matchedLevel) {
            const { id, ...rest } = matchedLevel; // Destructure to exclude 'id'
            // Copy the matchedLevel object except for the 'id' property
            warningLevel = { ...rest }; // Assign the rest of the properties to warningLevel
          }
        }
        templateId = matchedLevel ? matchedLevel.documentTemplateId : "";
      } else {
        // Handle other cases based on the form status
        switch (this.form.status) {
          case "invoice":
            templateId = this.modelData?.invoiceTemplateId ?? "";
            break;
          case "invoice-correction":
            templateId = this.modelData?.invoiceCorrectionTemplateId ?? "";
            break;
          case "invoice-storno":
            templateId = this.modelData?.invoiceStornoTemplateId ?? "";
            break;
          default:
            templateId = this.modelData?.invoiceTemplateId ?? ""; // Fallback to invoiceTemplateId
        }
      }
      if (templateId != "") {
        // Safeguard to ensure convertDate returns a valid Date object
        const parseDate = (date) => {
          const parsedDate = this.convertDate(date);
          return parsedDate instanceof Date && !isNaN(parsedDate)
            ? parsedDate
            : new Date(date);
        };

        const invoiceDate = this.form?.invoiceDate
          ? parseDate(this.form.invoiceDate)
          : this.formatDateLite(new Date());

        const startDate = this.form?.startDate
          ? parseDate(this.form.startDate)
          : null;

        const endDate = this.form?.endDate
          ? parseDate(this.form.endDate)
          : null;

        const dueDate = this.form?.dueDate
          ? parseDate(this.form.dueDate)
          : null;

        const draftStatusChangeDate = this.form?.invoiceDate
          ? parseDate(this.form.invoiceDate)
          : null;
        const totalTaxes = this.calculateTax.reduce((sum, tax) => {
          return sum + parseFloat(tax.amount);
        }, 0);
        // Create the payload
        const payload = {
          ...this.form,
          invoiceStatus: invoice.status,
          customers: this.form.companyId,
          totalAmount: this.totalAmount,
          totalTaxAmount: totalTaxes,
          totalAmountWithoutTax: this.totalNetto,
          invoiceDate: invoiceDate.toLocaleDateString(),
          startDate: startDate ? startDate.toLocaleDateString() : null,
          endDate: endDate ? endDate.toLocaleDateString() : null,
          dueDate: dueDate ? dueDate.toLocaleDateString() : null,
          draftStatusChangeDate: draftStatusChangeDate
            ? draftStatusChangeDate.toLocaleDateString()
            : "",
          id: templateId,
          output: documentType,
          updatedTime: Date.now(),
          ...warningLevel,
        };

        // Generate the filename
        const filename =
          "invoice-" +
          (this.form.invoiceNumber == null
            ? "draft"
            : this.form.invoiceNumber) +
          `.${documentType}`;

        // Dispatch the action
        const response = await this.$store.dispatch(
          "documentService/processTemplate",
          {
            data: payload,
            filename: filename,
            documentType: documentType,
          }
        );

        // If response is Blob, convert to Base64
        if (response instanceof Blob) {
          await this.convertBlobToBase64(response);
        }
      } else {
        NotificationService.showError("Please assign document template first");
      }
    },
    convertDate(dateString) {
      // Convert the date string into a Date object
      const date = new Date(dateString);

      // Convert the date to the desired format (Pakistan Standard Time)
      // Forcing timezone shift by setting the time to GMT+0500
      date.setTime(date.getTime() + 5 * 60 * 60 * 1000); // Add 5 hours

      // Return the formatted string
      return date.toString();
    },
    formatDateLite(date, showTime = false) {
      try {
        const m = date.getMonth() + 1;
        const d = date.getDate();
        return `${date.getFullYear()}-${m < 10 ? "0" : ""}${m}-${
          d < 10 ? "0" : ""
        }${d}${showTime ? " " + date.toLocaleTimeString() : ""}`;
      } catch (e) {
        return date;
      }
    },
    convertBlobToBase64(data) {
      return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onerror = (err) => {
          reject(err);
        };
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    },
    async updateInvoice() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          let payload = {};
          let storeDispatch = "invoices/update";
          if (this.invoiceStatus !== "draft") {
            payload = { status: this.form.status };
            storeDispatch = "invoices/updateStatus";
          } else {
            const totalTaxes = this.calculateTax.reduce((sum, tax) => {
              return sum + parseFloat(tax.amount);
            }, 0);

            payload = {
              ...this.form,
              companyId: this.form.companyId?.id ?? "",
              totalAmount: this.totalAmount,
              taxAmount: totalTaxes,
              referenceInvoiceId: this.referenceInvoiceDetail?.id ?? null,
              netto: this.totalNetto,
            };
          }

          this.isLoading = true;
          await this.$store
            .dispatch(storeDispatch, {
              id: this.$route.params.id,
              data: payload,
            })
            .finally(() => {
              this.isLoading = false;
            });
          this.$router.push("/invoices");
        }
      });
    },
  },
};
</script>

<style scoped>
#nprogress {
  position: relative;
  z-index: 9999999;
}

.white-color {
  color: white !important;
}

.vgt-responsive {
  overflow-x: visible !important;
}
</style>
