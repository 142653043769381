<template>
  <div>
    <PageHeader :items="items" />
    <validation-observer ref="simpleRules">
      <div class="card">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-md-6">
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <TextInput
                  v-model="form.name"
                  :label="$t('Name')"
                  :tooltipMessage="$t('Name')"
                  :required="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-md-6">
              <validation-provider
                #default="{ errors }"
                name="Credits"
                rules="required"
              >
                <TextInput
                  v-model="form.credits"
                  type="number"
                  :label="$t('Credits')"
                  :required="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-6">
              <validation-provider
                #default="{ errors }"
                name="Costs"
                rules="required"
              >
                <TextInput
                  v-model="form.costs"
                  type="number"
                  :label="$t('Costs')"
                  :required="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-md-6 mb-2 d-flex flex-column">
              <label class="input-label form-label">{{ $t("Image") }} </label>
              <input
                type="file"
                @change="addFile($event, 'image')"
                accept="image/*"
              />
              <div class="news-image" v-if="image.url">
                <img :src="image.url" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <b-button
          variant="primary"
          @click="updatePackage"
          class="d-flex align-items-center gap-1"
        >
          <feather-icon icon="PlusIcon" size="12" />
          <span>{{ $t("Update Package") }}</span>
        </b-button>
      </div>
    </validation-observer>
    <div class="card mt-3">
      <div class="card-body">
        <div class="d-flex justify-content-between align-items-center">
          <h2>{{ $t("Country Packages") }}</h2>
          <button
            @click="openModal('add')"
            class="btn btn-primary d-flex align-items-center gap-1"
          >
            <feather-icon :icon="'AddIcon'" size="12" />
            <span>{{ $t("Create Country Package") }}</span>
          </button>
        </div>
        <div
          class="d-flex justify-content-between align-items-center mb-1 mt-1"
        >
          <div class="custom-search d-flex justify-content-between">
            <b-form-group>
              <div class="d-flex align-items-center">
                <span class="text-nowrap"> {{ $t("Rows per page") }} </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['25', '50', '100']"
                  class="ml-1"
                  @input="(value) => onPerPageChange({ pageLength: value })"
                />
              </div>
            </b-form-group>
          </div>
          <div>
            <input
              autocomplete="off"
              type="text"
              name="search"
              class="form-control"
              :placeholder="$t('Search…')"
              v-model="search"
            />
          </div>
        </div>
        <div class="table-responsive api-keys-table">
          <!-- table -->
          <vue-good-table
            styleClass="vgt-table striped"
            :columns="columns"
            :fixed-header="false"
            :rows="rows"
            :search-options="{
              enabled: false,
              externalQuery: searchTerm,
              trigger: 'enter',
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
            mode="remote"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-per-page-change="onPerPageChange"
          >
            <template slot="table-row" slot-scope="props">
              <!-- Column: Name -->

              <span
                class="d-flex align-items-center justify-content-center gap-2"
                v-if="props.column.field === 'action'"
              >
                <div
                  class="cursor-pointer"
                  @click="openModal('edit', props.row)"
                >
                  <feather-icon size="16" icon="Edit2Icon" />
                </div>
                <div class="cursor-pointer" @click="destroy(props.row.id)">
                  <feather-icon size="16" icon="TrashIcon" />
                </div>
              </span>
              <span v-else-if="props.column.field === 'costs'">
                {{
                  $formatter(props.row.costs, $i18n.locale, "EUR", false, 2, 2)
                }}</span
              >
              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-end flex-wrap">
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="totalRecords"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value) => onPageChange({ currentPage: value })"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>

    <b-modal
      id="edit-tag-modal"
      v-model="showModal"
      :title="
        modalType === 'add' ? 'Create Country Package' : 'Edit Country Package'
      "
      centered
      size="lg"
      hide-footer
    >
      <validation-observer ref="countryPackageRules">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-label input-label" for="country"
                ><span style="color: red">*</span>&nbsp;{{
                  $t("Country")
                }}</label
              >
              <validation-provider
                #default="{ errors }"
                name="Country"
                rules="required"
              >
                <multi-select
                  :multiple="false"
                  label="name"
                  track-by="name"
                  :options="
                    modalType === 'add' ? filteredCountries : editCountriesList
                  "
                  :key="countries"
                  v-model="costs.country"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="col-md-6">
            <validation-provider
              #default="{ errors }"
              name="Credits"
              rules="required"
            >
              <TextInput
                v-model="costs.credits"
                type="number"
                :label="$t('Credits')"
                :tooltipMessage="$t('Credits')"
                :required="true"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>
          <div class="col-md-6">
            <validation-provider
              #default="{ errors }"
              name="Costs"
              rules="required"
            >
              <TextInput
                v-model="costs.costs"
                type="number"
                :label="$t('Costs')"
                :tooltipMessage="$t('Costs')"
                :required="true"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-end mt-2">
          <b-button class="mr-2" @click="showModal = false" variant="primary">{{
            $t("Cancel")
          }}</b-button>
          <b-button
            v-if="modalType === 'add'"
            @click="saveCountryPackages()"
            variant="primary"
            >{{ $t("Create Country Package") }}</b-button
          >
          <b-button v-else @click="editCountryPackages()" variant="primary">{{
            $t("Edit Country Package")
          }}</b-button>
        </div>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import TextInput from "@/components/TextInput.vue";
import PageHeader from "@/components/PageHeader.vue";
import MultiSelect from "vue-multiselect";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapGetters } from "vuex";
import countries from "@/assets/data/countries.json";
import { required, email } from "@validations";

export default {
  components: {
    TextInput,
    PageHeader,
    MultiSelect,
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    items() {
      return [
        {
          text: "Dental Twin",
          to: "/home",
        },
        {
          text: this.$t("Packages"),
          to: "/packages",
        },
        {
          text: this.$t("Edit"),
          active: true,
        },
      ];
    },
    columns() {
      return [
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Country"),
          field: "country",
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Credits"),
          field: "credits",
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Costs"),
          field: "costs",
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Action"),
          field: "action",
          sortable: false,
        },
      ];
    },
  },
  watch: {
    search(...val) {
      this.loadItems();
    },
  },
  data() {
    return {
      countries,
      form: {
        name: "",
        credits: "",
        costs: "",
        image: {},
      },
      costs: {
        country: "",
        credits: "",
        costs: "",
      },
      selectedId: "",
      excludedCountries: [],
      filteredCountries: [],
      editCountriesList: [],
      search: "",
      totalRecords: 0,
      pageLength: 25,
      page: 1,
      rows: [],
      showModal: false,
      searchTerm: "",
      sortBy: "createdAt",
      sortOrder: "desc",
      modalType: "add",
      image: {},
    };
  },
  async mounted() {
    try {
      this.$store.commit("showLoader", true);
      await this.$store
        .dispatch("packages/show", this.$route.params.id)
        .then(async (res) => {
          var response = res?.data?.data;
          this.form.name = response?.name;
          this.form.credits = response?.credits;
          this.form.costs = response?.costs;

          this.image = response?.image ?? {};
        });
      this.loadItems();
    } catch (e) {
      console.error("api error ___", e);
    } finally {
      this.$store.commit("showLoader", false);
    }
  },
  methods: {
    addFile(event, type) {
      const file = event.target.files[0];
      if (file instanceof Blob) {
        let reader = new FileReader();

        const readFileAsBase64 = () => {
          return new Promise((resolve, reject) => {
            reader.readAsDataURL(file);
            reader.onload = () => {
              const requiredData = reader.result;
              const data = {
                name: file.name,
                size: file.size,
                base64: requiredData,
              };
              resolve(data);
            };
            reader.onerror = (error) => {
              reject(error);
            };
          });
        };
        (async () => {
          try {
            const data = await readFileAsBase64();
            this.form.image = data;
          } catch (error) {
            console.error("Error reading file:", error);
          }
        })();
      }
    },
    async updatePackage() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          try {
            this.$store.commit("showLoader", true);
            await this.$store.dispatch("packages/update", {
              id: this.$route.params.id,
              data: {
                ...this.form,
              },
            });
            this.$router.push("/packages");
          } catch (e) {
            console.error("api error ___", e);
          } finally {
            this.$store.commit("showLoader", false);
          }
        }
      });
    },
    async saveCountryPackages() {
      this.$refs.countryPackageRules.validate().then(async (success) => {
        if (success) {
          await this.$store
            .dispatch("countryPackages/create", {
              ...this.costs,
              country: this.costs.country?.name ?? "",
              packageId: this.$route.params.id,
            })
            .then(async (res) => {
              this.showModal = false;
              this.costs = {
                country: "",
                credits: "",
                costs: "",
              };
              this.loadItems();
            });
        }
      });
    },
    async editCountryPackages() {
      this.$refs.countryPackageRules.validate().then(async (success) => {
        if (success) {
          await this.$store
            .dispatch("countryPackages/update", {
              id: this.selectedId,
              data: {
                ...this.costs,
                country: this.costs.country?.name ?? "",
                packageId: this.$route.params.id,
              },
            })
            .then(async (res) => {
              this.showModal = false;
              this.costs = {
                country: "",
                credits: "",
                costs: "",
              };
              this.loadItems();
            });
        }
      });
    },
    onSortChange(params) {
      this.sortOrder = params[0].type;
      if (params[0].type == "none") this.sortOrder = "asc";

      this.sortBy = params[0].field;
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ pageLength: params.pageLength });
      this.loadItems();
    },
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this, newProps);
    },
    openModal(type, item = null) {
      this.costs = {
        country: "",
        credits: "",
        costs: "",
      };
      this.modalType = type;
      if (type == "edit") {
        this.selectedId = item.id;
        const matchedCountry = this.countries.find(
          (country) => country.name === item.country
        );
        this.editCountriesList = this.countries.filter(
          (country) =>
            country.name === item.country ||
            !this.excludedCountries.includes(country.name)
        );
        this.costs = {
          country: matchedCountry,
          credits: item.credits,
          costs: item.costs,
        };
      }
      this.showModal = true;
    },
    // load items is what brings back the rows from server
    async loadItems() {
      try {
        this.$store.commit("showLoader", true);
        let response = await this.$store.dispatch("countryPackages/list", {
          page: this.page,
          search: this.search,
          perPage: this.pageLength,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder,
          packageId: this.$route.params.id,
        });
        this.rows = response?.data?.data;
        this.totalRecords = response?.data?.count;
        this.excludedCountries = response?.data?.countryNames;
        this.filteredCountries = this.countries.filter(
          (country) => !this.excludedCountries.includes(country.name)
        );
      } catch (e) {
        console.error("api error ___", e);
      } finally {
        this.$store.commit("showLoader", false);
      }
    },
    async destroy(id) {
      this.$swal({
        title: this.$t("Do you want to delete this record?"),
        text: this.$t("You can't revert your action"),
        type: "warning",
        customClass: "custom-delete-popup",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes delete it!"),
        cancelButtonText: this.$t("No"),
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          await this.$store
            .dispatch("countryPackages/destroy", id)
            .finally(() => {
              this.loadItems();
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#nprogress {
  position: relative;
  z-index: 9999999;
}

.white-color {
  color: white !important;
}

.vgt-responsive {
  overflow-x: visible !important;
}

.news-image {
  margin-top: 10px;
  img {
    width: 200px;
    object-fit: contain;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 5px;
    border-radius: 5px;
  }
}
</style>
