<template>
  <div>
    <PageHeader :items="items" />
    <validation-observer ref="simpleRules">
      <div class="card">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-md-3">
              <MultiSelectInput
                v-model="invoiceTemplate.mailTemplateId"
                :options="templates"
                :key="invoiceTemplate.mailTemplateId"
                :multiple="false"
                :textLabel="$t('Invoice Template')"
                label="subject"
                trackBy="id"
                class="pb-8 pr-6"
                moduleName="mailTemplates"
                :search-param-name="'search_string'"
              >
              </MultiSelectInput>
            </div>
            <div class="col-md-3">
              <TextInput
                class="pb-8 pr-6"
                :label="$t('CC')"
                v-model="invoiceTemplate.cc"
              />
            </div>
            <div class="col-md-3">
              <TextInput
                class="pb-8 pr-6"
                :label="$t('BCC')"
                v-model="invoiceTemplate.bcc"
              />
            </div>
            <div class="col-md-3">
              <TextInput
                class="pb-8 pr-6"
                :label="$t('Sender Mail')"
                v-model="invoiceTemplate.senderMail"
              />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-3">
              <MultiSelectInput
                v-model="invoiceCorrectionTemplate.mailTemplateId"
                :options="templates"
                :key="invoiceCorrectionTemplate.mailTemplateId"
                :multiple="false"
                :textLabel="$t('Invoice Correction Template')"
                label="subject"
                trackBy="id"
                class="pb-8 pr-6"
                moduleName="mailTemplates"
                :search-param-name="'search_string'"
              >
              </MultiSelectInput>
            </div>
            <div class="col-md-3">
              <TextInput
                class="pb-8 pr-6"
                :label="$t('CC')"
                v-model="invoiceCorrectionTemplate.cc"
              />
            </div>
            <div class="col-md-3">
              <TextInput
                class="pb-8 pr-6"
                :label="$t('BCC')"
                v-model="invoiceCorrectionTemplate.bcc"
              />
            </div>
            <div class="col-md-3">
              <TextInput
                class="pb-8 pr-6"
                :label="$t('Sender Mail')"
                v-model="invoiceCorrectionTemplate.senderMail"
              />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-3">
              <MultiSelectInput
                v-model="invoiceStornoTemplate.mailTemplateId"
                :options="templates"
                :key="invoiceStornoTemplate.mailTemplateId"
                :multiple="false"
                :textLabel="$t('Invoice Storno Template')"
                label="subject"
                trackBy="id"
                class="pb-8 pr-6"
                moduleName="mailTemplates"
                :search-param-name="'search_string'"
              >
              </MultiSelectInput>
            </div>
            <div class="col-md-3">
              <TextInput
                class="pb-8 pr-6"
                :label="$t('CC')"
                v-model="invoiceStornoTemplate.cc"
              />
            </div>
            <div class="col-md-3">
              <TextInput
                class="pb-8 pr-6"
                :label="$t('BCC')"
                v-model="invoiceStornoTemplate.bcc"
              />
            </div>
            <div class="col-md-3">
              <TextInput
                class="pb-8 pr-6"
                :label="$t('Sender Mail')"
                v-model="invoiceStornoTemplate.senderMail"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <b-button
          variant="primary"
          @click="createTemplate"
          class="d-flex align-items-center gap-1"
        >
          <feather-icon icon="PlusIcon" size="12" />
          <span>{{ $t("Create Template") }}</span>
        </b-button>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import TextInput from "@/components/TextInput.vue";
import PageHeader from "@/components/PageHeader.vue";
import MultiSelect from "vue-multiselect";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapGetters } from "vuex";
import { required, email } from "@validations";
import MultiSelectInput from "@/components/MultiSelectInput.vue";

export default {
  components: {
    TextInput,
    PageHeader,
    MultiSelect,
    ValidationProvider,
    ValidationObserver,
    MultiSelectInput,
  },
  computed: {
    ...mapGetters("mailTemplates", ["templates"]),
    items() {
      return [
        {
          text: "Dental Twin",
          to: "/home",
        },
        {
          text: this.$t("Settings"),
          to: "/settings",
        },
        {
          text: this.$t("Mail Template Assignment"),
          active: true,
        },
      ];
    },
  },
  data() {
    return {
      invoiceTemplate: {
        module: "invoiceTemplate",
        mailTemplateId: "",
        cc: "",
        bcc: "",
        senderMail: "",
      },
      invoiceCorrectionTemplate: {
        module: "invoiceCorrectionTemplate",
        mailTemplateId: "",
        cc: "",
        bcc: "",
        senderMail: "",
      },
      invoiceStornoTemplate: {
        module: "invoiceStornoTemplate",
        mailTemplateId: "",
        cc: "",
        bcc: "",
        senderMail: "",
      },
    };
  },
  async mounted() {
    try {
      this.$store.commit("showLoader", true);
      await this.$store.dispatch("mailTemplates/list");
      const response = await this.$store.dispatch(
        "mailTemplates/mailTemplateAssignmentList"
      );
      response?.data?.data?.forEach(async (item) => {
        if (item.module === "invoiceTemplate") {
          this.invoiceTemplate = item;
          if (this.invoiceTemplate.mailTemplateId)
            //find the template object in the mail templates listing and set the found object to the mailTemplateId
            this.invoiceTemplate.mailTemplateId = await this.setTemplate(
              this.invoiceTemplate.mailTemplateId
            );
        }
        if (item.module === "invoiceCorrectionTemplate") {
          this.invoiceCorrectionTemplate = item;
          if (this.invoiceCorrectionTemplate.mailTemplateId)
            //find the template object in the mail templates listing and set the found object to the mailTemplateId

            this.invoiceCorrectionTemplate.mailTemplateId =
              await this.setTemplate(
                this.invoiceCorrectionTemplate.mailTemplateId
              );
        }
        if (item.module === "invoiceStornoTemplate") {
          this.invoiceStornoTemplate = item;
          if (this.invoiceStornoTemplate.mailTemplateId)
            //find the template object in the mail templates listing and set the found object to the mailTemplateId

            this.invoiceStornoTemplate.mailTemplateId = await this.setTemplate(
              this.invoiceStornoTemplate.mailTemplateId
            );
        }
      });
    } catch (e) {
      console.error("api error ___", e);
    } finally {
      this.$store.commit("showLoader", false);
    }
  },
  methods: {
    async createTemplate() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          try {
            this.$store.commit("showLoader", true);
            const modules = [];
            modules.push({
              ...this.invoiceTemplate,
              mailTemplateId: this.invoiceTemplate.mailTemplateId?.id ?? "",
            });
  
            modules.push({
              ...this.invoiceCorrectionTemplate,
              mailTemplateId:
                this.invoiceCorrectionTemplate.mailTemplateId?.id ?? "",
            });
            modules.push({
              ...this.invoiceStornoTemplate,
              mailTemplateId: this.invoiceStornoTemplate.mailTemplateId?.id ?? "",
            });
  
            await this.$store.dispatch(
              "mailTemplates/mailTemplateAssignmentSave",
              {
                modules: modules,
              }
            );
          } catch (e) {
            console.error("api error ___", e);
          } finally {
            this.$store.commit("showLoader", false);
          }
        }
      });
    },
  },
};
</script>

<style scoped>
#nprogress {
  position: relative;
  z-index: 9999999;
}

.white-color {
  color: white !important;
}

.vgt-responsive {
  overflow-x: visible !important;
}
</style>
