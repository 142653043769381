<template>
  <div>
    <PageHeader :items="items" />
    <validation-observer ref="simpleRules">
      <div class="card">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-md-6">
              <validation-provider
                #default="{ errors }"
                name="Company Name"
                rules="required"
              >
                <TextInput
                  v-model="form.companyName"
                  type="text"
                  :label="$t('Company Name')"
                  :tooltipMessage="$t('Company Name')"
                  :required="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-md-6">
              <validation-provider
                #default="{ errors }"
                name="Address Line 1"
                rules="required"
              >
                <TextInput
                  v-model="form.addressLine1"
                  type="text"
                  :label="$t('Address Line 1')"
                  :required="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-6">
              <TextInput
                v-model="form.addressLine2"
                type="text"
                :label="$t('Address Line 2')"
              />
            </div>
            <div class="col-md-6">
              <validation-provider
                #default="{ errors }"
                name="City"
                rules="required"
              >
                <TextInput
                  v-model="form.city"
                  type="text"
                  :label="$t('City')"
                  :required="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-6">
              <validation-provider
                #default="{ errors }"
                name="ZIP"
                rules="required"
              >
                <TextInput
                  v-model="form.zipCode"
                  type="text"
                  :label="$t('ZIP')"
                  :required="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-md-6">
              <validation-provider
                #default="{ errors }"
                name="Country"
                rules="required"
              >
                <TextInput
                  v-model="form.country"
                  type="text"
                  :label="$t('Country')"
                  :required="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-6">
              <TextInput
                v-model="form.vatId"
                type="text"
                :label="$t('VAT ID')"
              />
            </div>
            <div class="col-md-6">
              <TextInput
                v-model="form.phone"
                type="text"
                :label="$t('Phone')"
              />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-6">
              <label for="cars">{{ $t("Status") }}</label>
              <select class="input-form form-control" v-model="form.status">
                <option value="active">
                  {{ $t("Active") }}
                </option>
                <option value="inactive">
                  {{ $t("Inactive") }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" aria-label="Payment Details Header">
          <h3 class="card-title">
            {{ $t("Payment Details") }}
          </h3>
        </div>

        <div class="card-body" aria-label="Payment Detials Body">
          <div class="row mb-2">
            <div class="col-md-6">
              <text-input
                v-model="form.invoiceEmail"
                :label="$t('Invoice Email Address')"
                aria-label="Invoice Email Address Input"
              />
            </div>
            <div class="col-md-6">
              <text-input
                v-model="form.warningInvoiceEmail"
                :label="$t('Warning Mail Address')"
                aria-label="Invoice Email Address Input"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label for="cars">{{ $t("Apply Reverse Charge") }}</label>

              <input
                id="active"
                class="form-check-input-custom ml-2"
                v-model="form.applyReverseCharge"
                type="checkbox"
              />
            </div>
            <div class="col-md-6">
              <text-input
                v-model="form.externalOrderNumber"
                :label="$t('External Order Number')"
                aria-label="External Order Number"
              />
            </div>
            <div class="col-md-6">
              <text-input
                :type="'number'"
                v-model="form.credits"
                :label="$t('Credits')"
                aria-label="Credits"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" aria-label="Bank Details Header">
          <h3 class="card-title">
            {{ $t("Bank Details") }}
          </h3>
        </div>
        <div class="card-body" aria-label="Bank Details Body">
          <div class="w-100">
            <div
              v-for="(bankDetails, index) in form.bankDetails"
              :key="index"
              class=""
            >
              <div class="row">
                <div class="col-md-3">
                  <validation-provider
                    #default="{ errors }"
                    name="Bank Name"
                    rules="required"
                  >
                    <text-input
                      v-model="bankDetails.bankName"
                      :label="$t('Bank Name')"
                      :required="true"
                      :maxLength="100"
                      aria-label="Bank Name Input"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
                <div class="col-md-4">
                  <validation-provider
                    #default="{ errors }"
                    name="BIC/SWIFT"
                    rules="required"
                  >
                    <text-input
                      v-model="bankDetails.swift"
                      :label="$t('BIC/SWIFT')"
                      :required="true"
                      :maxLength="100"
                      aria-label="BIC/Swift Input"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
                <div class="col-md-4">
                  <validation-provider
                    #default="{ errors }"
                    name="IBAN"
                    rules="required"
                  >
                    <text-input
                      v-model="bankDetails.iban"
                      :label="$t('IBAN')"
                      :required="true"
                      :maxLength="100"
                      aria-label="IBAN Input"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
                <div class="col-1 my-auto">
                  <div
                    role="button"
                    class="float-center"
                    @click="deleteBankDetails(index)"
                    aria-label="Delete bank details button"
                  >
                    <feather-icon icon="TrashIcon" size="12" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="d-flex align-items-center flex-wrap me-n3 pt-2 ml-2"
            :class="[
              form.bankDetails && form.bankDetails.length === 0 ? 'p-4' : 'p-8',
            ]"
          >
            <div
              :class="[
                form.bankDetails && form.bankDetails.length === 0 ? '' : 'mb-2',
              ]"
              class="d-flex align-items-center"
            >
              <span
                style="cursor: pointer"
                @click="addBankDetails"
                class="ms-2 font-weight-medium text-primary"
              >
                <feather-icon :icon="'PlusIcon'" size="12" />
                {{ $t("Add bank accounts") }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex align-items-center justify-content-end">
        <b-button
          variant="primary"
          @click="updateCustomer"
          class="d-flex align-items-center gap-1"
        >
          <feather-icon icon="PlusIcon" size="12" />
          <span>{{ $t("Update Customer") }}</span>
        </b-button>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import TextInput from "@/components/TextInput.vue";
import PageHeader from "@/components/PageHeader.vue";
import MultiSelect from "vue-multiselect";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapGetters } from "vuex";
import { required, email } from "@validations";
export default {
  components: {
    TextInput,
    PageHeader,
    MultiSelect,
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    items() {
      return [
        {
          text: "Dental Twin",
          to: "/home",
        },
        {
          text: this.$t("Customers"),
          to: "/customers",
        },
        {
          text: this.$t("Edit"),
          active: true,
        },
      ];
    },
  },
  data() {
    return {
      form: {
        companyName: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        zipCode: "",
        country: "",
        credits: "",
        vatId: "",
        phone: "",
        status: "",
        bankDetails: [],
        externalOrderNumber: "",
        invoiceEmail: "",
        warningInvoiceEmail: "",
        applyReverseCharge: false,
      },
    };
  },
  created() {
    this.refresh();
  },
  methods: {
    async refresh() {
      try {
        this.$store.commit("showLoader", true);
        await this.$store
          .dispatch("customers/show", this.$route.params.id)
          .then((res) => {
            let data = res?.data?.modelData;
            this.form.companyName = data.companyName;
            this.form.addressLine1 = data.addressLine1;
            this.form.addressLine2 = data.addressLine2;
            this.form.zipCode = data.zipCode;
            this.form.credits = data.credits;
            this.form.city = data.city;
            this.form.status = data.status;
            this.form.country = data.country;
            this.form.externalOrderNumber = data.externalOrderNumber;
            this.form.vatId = data.vatId;
            this.form.phone = data.phone;
            this.form.invoiceEmail = data.invoiceEmail;
            this.form.warningInvoiceEmail = data.warningInvoiceEmail;
            this.form.bankDetails = data.bankDetails;
            this.form.applyReverseCharge = data.applyReverseCharge;
          });
      } catch (e) {
        console.error("api error ___", e);
      } finally {
        this.$store.commit("showLoader", false);
      }
    },
    addBankDetails() {
      this.form.bankDetails.push({
        bankName: "",
        swift: "",
        iban: "",
      });
    },
    async deleteBankDetails(index) {
      this.$swal({
        title: this.$t("Do you want to delete this record?"),
        text: this.$t("You can't revert your action"),
        type: "warning",
        customClass: "custom-delete-popup",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes delete it!"),
        cancelButtonText: this.$t("No"),
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          this.form.bankDetails.splice(index, 1);
        }
      });
    },
    async updateCustomer() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          await this.$store.dispatch("customers/update", {
            id: this.$route.params.id,
            data: {
              ...this.form,
            },
          });

          this.$router.push("/customers");
        }
      });
    },
  },
};
</script>

<style scoped>
#nprogress {
  position: relative;
  z-index: 9999999;
}

.white-color {
  color: white !important;
}

.vgt-responsive {
  overflow-x: visible !important;
}
</style>
