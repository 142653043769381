var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageHeader',{attrs:{"items":_vm.items,"optionalItems":_vm.optionalItems}}),_c('div',{staticClass:"custom-search d-flex justify-content-between"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("Rows per page"))+" ")]),_c('b-form-select',{staticClass:"ml-1",attrs:{"options":['25', '50', '100']},on:{"input":function (value) { return _vm.onPerPageChange({ pageLength: value }); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}})],1)])],1),_c('div',{staticClass:"table-responsive api-keys-table"},[_c('vue-good-table',{attrs:{"styleClass":"vgt-table striped","columns":_vm.columns,"fixed-header":false,"rows":_vm.rows,"search-options":{
        enabled: true,
        externalQuery: _vm.searchTerm,
        trigger: 'enter',
      },"pagination-options":{
        enabled: true,
        perPage: _vm.pageLength,
      },"mode":"remote"},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',{staticClass:"d-flex align-items-center justify-content-center gap-2"},[_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.$router.push(("/packages/" + (props.row.id) + "/show"))}}},[_c('feather-icon',{attrs:{"size":"16","icon":"EyeIcon"}})],1),(_vm.$can(((_vm.$route.meta.permission) + ".edit")))?_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.$router.push(("/packages/" + (props.row.id) + "/edit"))}}},[_c('feather-icon',{attrs:{"size":"16","icon":"Edit2Icon"}})],1):_vm._e(),(_vm.$can(((_vm.$route.meta.permission) + ".delete")))?_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.destroy(props.row.id)}}},[_c('feather-icon',{attrs:{"size":"16","icon":"TrashIcon"}})],1):_vm._e()]):(props.column.field === 'costs')?_c('span',[_vm._v(" "+_vm._s(_vm.$formatter(props.row.costs, _vm.$i18n.locale, "EUR", false, 2, 2)))]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-end flex-wrap"},[_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":_vm.totalRecords,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return _vm.onPageChange({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }