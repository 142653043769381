var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageHeader',{attrs:{"items":_vm.items}}),_c('validation-observer',{ref:"simpleRules"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 mb-2"},[_c('validation-provider',{attrs:{"name":"Subject","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":_vm.$t('Subject'),"tooltipMessage":_vm.$t('Subject'),"required":true},model:{value:(_vm.form.subject),callback:function ($$v) {_vm.$set(_vm.form, "subject", $$v)},expression:"form.subject"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"Language","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"input-label form-label"},[_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Language"))+" ")]),_c('multiselect',{staticStyle:{"z-index":"9999"},attrs:{"options":['english', 'french', 'german', 'spanish'],"multiple":false},model:{value:(_vm.form.language),callback:function ($$v) {_vm.$set(_vm.form, "language", $$v)},expression:"form.language"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-6 d-flex flex-column"},[_c('label',{staticClass:"input-label form-label"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.isHighlight),expression:"form.isHighlight"}],staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Thumbnail"))+" ")]),_c('input',{attrs:{"type":"file","accept":"image/*"},on:{"change":function (event) {
                  _vm.addFile(event, 'thumbnail');
                }}}),(_vm.thumbnail.url)?_c('div',{staticClass:"news-image"},[_c('img',{attrs:{"src":_vm.thumbnail.url}})]):_vm._e()]),_c('div',{staticClass:"col-md-6 mb-2 d-flex flex-column"},[_c('label',{staticClass:"input-label form-label"},[_vm._v(_vm._s(_vm.$t("Image"))+" ")]),_c('input',{attrs:{"type":"file","accept":"image/*"},on:{"change":function($event){return _vm.addFile($event, 'image')}}}),(_vm.file.url)?_c('div',{staticClass:"news-image"},[_c('img',{attrs:{"src":_vm.file.url}})]):_vm._e()]),_c('div',{staticClass:"col-md-12 mb-2"},[_c('validation-provider',{attrs:{"name":"Short Description","rules":{ required: _vm.form.isHighlight }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":_vm.$t('Short Description'),"tooltipMessage":_vm.$t('Short Description'),"required":_vm.form.isHighlight},model:{value:(_vm.form.shortDescription),callback:function ($$v) {_vm.$set(_vm.form, "shortDescription", $$v)},expression:"form.shortDescription"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-12 mb-1"},[_c('label',{attrs:{"for":"cars"}},[_vm._v(_vm._s(_vm.$t("Highlight")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.isHighlight),expression:"form.isHighlight"}],staticClass:"form-check-input-custom ml-2",attrs:{"id":"active","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.form.isHighlight)?_vm._i(_vm.form.isHighlight,null)>-1:(_vm.form.isHighlight)},on:{"change":function($event){var $$a=_vm.form.isHighlight,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "isHighlight", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "isHighlight", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "isHighlight", $$c)}}}})]),_c('div',{staticClass:"col-md-12"},[_c('validation-provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('label',{staticClass:"input-label form-label"},[_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Description"))+" ")]),_c('vue-editor',{model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])])]),_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-button',{staticClass:"d-flex align-items-center gap-1",attrs:{"variant":"primary"},on:{"click":_vm.updateNews}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"12"}}),_c('span',[_vm._v(_vm._s(_vm.$t("Update News")))])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }