<template>
  <div>
    <div>
      <div class="flex flex-wrap -mr-6 px-8 py-1">
        <div class="form-group w-full">
          <TextInput
            v-model="clientID"
            :isReadonly="true"
            :label="$t('Client ID')"
            class="w-full pr-2"
          />
          <b-button
            @click="copyText('clientID')"
            class="px-3 py-1 my-2 docsHeroColorBlue rounded"
          >
            {{ $t("Copy") }}
          </b-button>
        </div>
        <div class="form-group w-full mt-4">
          <TextInput
            v-model="clientSecret"
            :isReadonly="true"
            :label="$t('Client Secret')"
            class="w-full pr-2"
          />
          <b-button
            @click="copyText('clientSecret')"
            class="px-3 py-1 my-2 docsHeroColorBlue rounded"
          >
            {{ $t("Copy") }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/TextInput.vue";

export default {
  props: {
    clientSecret: {
      type: String,
      default: "",
    },
    clientID: {
      type: String,
      default: "",
    },
  },
  components: {
    TextInput,
  },
  methods: {
    copyText(type) {
      // Copy the text inside the text field
      navigator.clipboard.writeText(
        type === "clientID" ? this.clientID : this.clientSecret
      );

      // Alert the copied text
      alert(
        (type === "clientID" ? "Client ID" : "Client Secret") +
          " " +
          "copied successfully!"
      );
    },
  },
};
</script>

<style scoped></style>
