<template>
  <div>
    <PageHeader :items="items" />
    <div class="mycases create-case">
      <div class="pageheader">
        <h2>{{ $t("Cases") }}: {{ totalRecords }}</h2>
        <div class="pageheader-right">
          <div class="d-flex align-items-center gap-1 filter-by-color">
            <b-form-group class="mb-0" label="" label-for="status-filter">
              <select
                @change="applyStatusFilter"
                class="form-control"
                v-model="form.status"
              >
                <option
                  v-for="option in filterOptions"
                  :value="option.value"
                  :key="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
            </b-form-group>
          </div>
          <div class="d-flex align-items-center gap-1 filter-by-color">
            <p>{{ $t("Per page") }}</p>
            <b-form-group class="m-0">
              <select class="form-control" v-model="form.perPage">
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
              </select>
            </b-form-group>
          </div>
          <div class="search">
            <div class="icon">
              <ElementIcon v-bind:icon="'searchIcon'" />
            </div>
            <input
              type="text"
              v-model="form.search"
              :placeholder="$t('Search for...')"
            />
          </div>
          <div class="divider"></div>
          <div class="d-flex align-items-center gap-1 filter-by-color">
            <p>{{ $t("Filter by") }}</p>
            <b-form-group class="m-0">
              <select class="form-control" v-model="form.selectedFilter">
                <option value="all">All</option>
                <option
                  v-for="flag in existingFlags"
                  :key="flag.id"
                  :value="flag.id"
                  :style="{ color: flag.color }"
                >
                  {{ flag.name }}
                </option>
              </select>
            </b-form-group>
          </div>
          <div class="d-flex align-items-center gap-1 filter-by-color">
            <p>{{ $t("At least") }}</p>
            <StarRating
              :value="filterRatingValue"
              @setRatingValue="setFilterRatingValue"
            />
          </div>
        </div>
      </div>

      <!-- Cases Display -->
      <div class="card case-m-card">
        <div class="card-body">
          <div class="table-responsive api-keys-table">
            <!-- table -->
            <vue-good-table
              styleClass="vgt-table striped"
              :columns="columns"
              :fixed-header="false"
              :rows="rows"
              :pagination-options="{
                enabled: true,
              }"
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
            >
              <template v-slot:table-column="props">
                <span
                  class="d-flex align-items-center position-relative zindex-4"
                  style="gap: 15px"
                  v-if="props.column.field === 'name'"
                >
                  <div class="checkbox-s-group">
                    <input
                      type="checkbox"
                      class="checkbox-input"
                      id="selectAll"
                      v-model="selectAll"
                      @change="toggleSelectAll(props.row)"
                    />
                    <label
                      for="selectAll"
                      class="checkbox-label m-0 text-white"
                      :title="$t('Select All')"
                    >
                    </label>
                  </div>
                  <span>{{ $t("Cases") }}</span>
                </span>
              </template>
              <template slot="table-row" slot-scope="props">
                <!-- Column: Name -->
                <span
                  class="d-flex align-items-center justify-content-center action"
                  v-if="props.column.field === 'action'"
                >
                  <!-- <p>{{ props.row.status }}</p> -->
                  <div
                    v-if="$can(`${$route.meta.permission}.edit`)"
                    class="cursor-pointer"
                    @click="$router.push(`/my-cases/${props.row.id}/edit`)"
                  >
                    <feather-icon size="16" icon="Edit2Icon" />
                  </div>
                  <b-nav-item-dropdown
                    right
                    toggle-class="d-flex align-items-center"
                    class=""
                  >
                    <template #button-content>
                      <div class="">
                        <ElementIcon v-bind:icon="'barVerticalIcon'" />
                      </div>
                    </template>

                    <b-dropdown-item
                      @click="
                        handlePreviewClick(
                          props.row.files['upper'],
                          props.row.files['lower']
                        )
                      "
                      link-class="d-flex align-items-center"
                    >
                      <feather-icon size="16" icon="InfoIcon" class="mr-50" />

                      <span>
                        {{ $t("Preview Original") }}
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      link-class="d-flex align-items-center"
                      @click="
                        downloadFiles([
                          props.row.files['upper'],
                          props.row.files['lower'],
                        ])
                      "
                    >
                      <feather-icon
                        size="16"
                        icon="DownloadIcon"
                        class="mr-50"
                      />

                      <span>
                        {{ $t("Download Original") }}
                      </span>
                    </b-dropdown-item>
                    <!-- @click="
                            handlePreviewClick(
                              props.row.results['upper'],
                              props.row.results['lower']
                            )
                          " -->
                    <div v-if="props.row.results">
                      <b-dropdown-item
                        @click="
                          handlePreviewResultClick([
                            props.row.results['upper_rotated'] ||
                              props.row.results['upper'],
                            props.row.results['lower_rotated'] ||
                              props.row.results['lower'],
                          ])
                        "
                        link-class="d-flex align-items-center"
                      >
                        <feather-icon size="16" icon="InfoIcon" class="mr-50" />

                        <span>
                          {{ $t("Preview Result") }}
                        </span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="
                          downloadFiles([
                            props.row.results['upper_rotated'] ||
                              props.row.results['upper'],
                            props.row.results['lower_rotated'] ||
                              props.row.results['lower'],
                          ])
                        "
                        link-class="d-flex align-items-center"
                      >
                        <feather-icon
                          size="16"
                          icon="DownloadIcon"
                          class="mr-50"
                        />

                        <span>
                          {{ $t("Download Result") }}
                        </span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="
                          handlePreviewResultClick([
                            props.row.results['upper'],
                            props.row.results['lower'],
                          ])
                        "
                        link-class="d-flex align-items-center"
                      >
                        <feather-icon size="16" icon="InfoIcon" class="mr-50" />

                        <span>
                          {{ $t("Preview Without Rotation") }}
                        </span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="
                          downloadFiles([
                            props.row.results['upper'],
                            props.row.results['lower'],
                          ])
                        "
                        link-class="d-flex align-items-center"
                      >
                        <feather-icon
                          size="16"
                          icon="DownloadIcon"
                          class="mr-50"
                        />

                        <span>
                          {{ $t("Download Without Rotation") }}
                        </span>
                      </b-dropdown-item>
                    </div>

                    <b-dropdown-item
                      @click="approveCase(props.row.id, 'approved')"
                      link-class="d-flex align-items-center"
                    >
                      <feather-icon size="16" icon="CheckIcon" class="mr-50" />

                      <span>
                        {{ $t("Approve Case") }}
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="replicateCase(props.row.id)"
                      link-class="d-flex align-items-center"
                    >
                      <feather-icon size="16" icon="CopyIcon" class="mr-50" />

                      <span>
                        {{ $t("Recalculate") }}
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      link-class="d-flex align-items-center"
                      @click="approveCase(props.row.id, 'rejected')"
                    >
                      <feather-icon size="16" icon="XIcon" class="mr-50" />
                      <span>
                        {{ $t("Reject Case") }}
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="updateStatus(props.row.id, 'finished')"
                      link-class="d-flex align-items-center"
                    >
                      <feather-icon size="16" icon="FlagIcon" class="mr-50" />

                      <span>
                        {{ $t("Finished") }}
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="$can('ticket.create')"
                      link-class="d-flex align-items-center"
                      @click="reportCase(props.row)"
                    >
                      <feather-icon size="16" icon="FlagIcon" class="mr-50" />
                      <span>
                        {{ $t("Report Case") }}
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="updateStatus(props.row.id, 'trash')"
                      link-class="d-flex align-items-center"
                    >
                      <feather-icon size="16" icon="TrashIcon" class="mr-50" />

                      <span>
                        {{ $t("Trash") }}
                      </span>
                    </b-dropdown-item>
                  </b-nav-item-dropdown>
                </span>
                <span
                  class="d-flex align-items-center"
                  style="gap: 15px"
                  v-else-if="props.column.field === 'name'"
                >
                  <div class="checkbox-s-group">
                    <input
                      type="checkbox"
                      class="checkbox-input"
                      :id="'case-' + props.row.id"
                      v-model="selectedCases"
                      :value="props.row.id"
                    />
                    <label
                      :for="'case-' + props.row.id"
                      class="checkbox-label m-0"
                    ></label>
                  </div>
                  <p class="">{{ props.row.name }}</p>
                </span>
                <span
                  class="d-flex align-items-center justify-content-center gap-2"
                  v-else-if="props.column.field === 'status'"
                >
                  <p class="status-tag" :class="props.row.caseStatus">
                    {{ props.row.caseStatus }}
                  </p>
                  <p
                    v-if="props.row.isApproved == 1"
                    class="status-tag"
                    :class="{ approved: props.row.isApproved == 1 }"
                  >
                    {{ $t("Approved") }}
                  </p>
                  <p
                    v-if="props.row.isRejected == 1"
                    class="status-tag"
                    :class="{ rejected: props.row.isRejected == 1 }"
                  >
                    {{ $t("Rejected") }}
                  </p>
                </span>
                <span v-else-if="props.column.field === 'upperCase'">
                  <div class="failed" v-if="props.row.caseStatus == 'failed'">
                    <ElementIcon v-bind:icon="'xIcon'" />
                  </div>
                  <div
                    class="failed"
                    v-else-if="props.row.caseStatus == 'unsupported'"
                  >
                    <ElementIcon v-bind:icon="'xIcon'" />
                  </div>
                  <span
                    class="hour-glass"
                    v-else-if="
                      props.row.caseStatus == 'queued' ||
                      props.row.caseStatus == 'in-progress'
                    "
                    ><img src="@/assets/images/svg/hourglass.gif" alt=""
                  /></span>
                  <div
                    class="d-flex align-items-center justify-content-center flex-column"
                    v-else
                  >
                    <img
                      v-if="
                        props.row.results && props.row.results['upper_image']
                      "
                      :src="props.row.upperImageSrc"
                      alt=""
                    />

                    <h4
                      v-if="
                        props.row.results && props.row.results['upper_image']
                      "
                      class="text-center mt-2"
                    >
                      {{ $t("Upper") }}
                    </h4>
                    <div
                      v-if="
                        props.row.results && props.row.results['upper_image']
                      "
                    >
                      <StarRating
                        :key="props.row.id"
                        :value="
                          (props.row.ratings &&
                            props.row.ratings.upper_case_rating) ||
                          0
                        "
                        @setRatingValue="
                          setRatingValue($event, props.row.id, 'upper')
                        "
                      />
                    </div>
                  </div>
                </span>
                <span v-else-if="props.column.field === 'lowerCase'">
                  <div class="failed" v-if="props.row.caseStatus == 'failed'">
                    <ElementIcon v-bind:icon="'xIcon'" />
                  </div>
                  <div
                    class="failed"
                    v-else-if="props.row.caseStatus == 'unsupported'"
                  >
                    <ElementIcon v-bind:icon="'xIcon'" />
                  </div>
                  <span
                    class="hour-glass"
                    v-else-if="
                      props.row.caseStatus == 'queued' ||
                      props.row.caseStatus == 'in-progress'
                    "
                    ><img src="@/assets/images/svg/hourglass.gif" alt=""
                  /></span>
                  <div
                    class="d-flex align-items-center justify-content-center flex-column"
                    v-else
                  >
                    <img
                      v-if="
                        props.row.results && props.row.results['lower_image']
                      "
                      :src="props.row.lowerImageSrc"
                      alt=""
                    />
                    <h4
                      v-if="
                        props.row.results && props.row.results['lower_image']
                      "
                      class="text-center mt-2"
                    >
                      {{ $t("Lower") }}
                    </h4>
                    <div
                      v-if="
                        props.row.results && props.row.results['lower_image']
                      "
                    >
                      <StarRating
                        :key="props.row.id"
                        :value="
                          (props.row.ratings &&
                            props.row.ratings.lower_case_rating) ||
                          0
                        "
                        @setRatingValue="
                          setRatingValue($event, props.row.id, 'lower')
                        "
                      />
                    </div>
                  </div>
                </span>
                <span v-else-if="props.column.field === 'date'">
                  <p class="">{{ dateFormatter(props.row.creation_date) }}</p>
                </span>
                <span v-else-if="props.column.field === 'isconnected'">
                  <div
                    class="d-flex align-items-center justify-content-center gap-2"
                  >
                    <p class="status-tag" v-if="props.row.isConnected === 1">
                      {{ $t("Connected") }}
                    </p>
                  </div>
                </span>
                <span
                  class="d-flex align-items-center justify-content-center"
                  v-else-if="props.column.field === 'flag'"
                >
                  <div
                    class="tag"
                    :style="{
                      backgroundColor: props.row.tagColor,
                    }"
                    @click="openModal(props.row)"
                    :title="props.row.flag ? props.row.flag.name : ''"
                  ></div>
                </span>
                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-center flex-wrap mt-2">
                  <div>
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRecords"
                      :per-page="form.perPage"
                      @input="(value) => onPageChange({ currentPage: value })"
                      aria-controls="case-list"
                    ></b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
      <CookieButton>
        <template #cookieBtns>
          <button
            :disabled="selectedCases.length == 0"
            class="btn btn-secondary mr-1"
            @click="downloadCaseFiles()"
          >
            {{ $t("Download") }}
          </button>
          <button
            :disabled="selectedCases.length == 0"
            class="btn btn-secondary mr-1"
            @click="approveCases('approved')"
          >
            {{ $t("Approve Cases") }}
          </button>
          <button
            @click="approveCases('reject')"
            :disabled="selectedCases.length == 0"
            class="btn btn-secondary"
          >
            {{ $t("Reject Cases") }}
          </button>
        </template>
      </CookieButton>
      <!-- B-Modal for editing tag -->
      <b-modal
        id="edit-tag-modal"
        v-model="showModal"
        title="Edit Flag"
        centered
        size="md"
        hide-footer
      >
        <b-form @submit.prevent="updateCase" class="modal-form">
          <validation-observer ref="simpleRules">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Flag"
                rules="required"
              >
                <label class="form-label input-label" for="flag"
                  >{{ $t("Flag") }}&nbsp;<span style="color: #f00">*</span>
                </label>
                <multi-select
                  v-model="tag.flag"
                  :options="existingFlags"
                  :taggable="true"
                  tag-placeholder="Add a tag"
                  placeholder="Select or add a tag"
                  @tag="addFlag"
                  label="name"
                  track-by="name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Set Flag Color">
              <b-dropdown
                id="colorDropdown"
                class="form-control form-dropdown"
                variant="link"
              >
                <template #button-content>
                  <p class="d-flex align-items-center" v-if="tag.tagColor">
                    <span
                      class="mr-1"
                      :style="{
                        backgroundColor: tag.tagColor,
                        width: '20px',
                        height: '20px',
                        display: 'inline-block',
                        borderRadius: '20px',
                      }"
                    >
                    </span>
                    {{ tag.tagColor || "None" }}
                  </p>
                  <p v-else>{{ $t("Select a color") }}</p>
                  <feather-icon icon="ChevronDownIcon" size="20" />
                </template>
                <div class="color-pettle">
                  <b-dropdown-item
                    v-for="color in colors"
                    :key="color.value"
                    @click="tag.tagColor = color.value"
                  >
                    <span
                      class="color-box"
                      :style="{
                        backgroundColor: color.value,
                      }"
                    >
                    </span>
                    <!-- {{ color.name }} -->
                  </b-dropdown-item>
                </div>
              </b-dropdown>
            </b-form-group>
            <div class="d-flex align-items-center justify-content-end mt-2">
              <b-button type="submit" variant="primary">{{
                $t("Save")
              }}</b-button>
            </div>
          </validation-observer>
        </b-form>
      </b-modal>
      <!---===================================-->
      <!---===================================-->
      <div class="c-modal" v-show="showPreviewModal">
        <div class="c-modal-content">
          <div class="c-modal-header">
            <h3>{{ $t("Preview") }}</h3>
            <div class="x-icon" @click="closePreviewModal">
              <feather-icon size="16" icon="XIcon" />
            </div>
          </div>
          <div class="c-modal-body">
            <PreviewModel
              v-if="showPreviewModal"
              :upper="currentUpper"
              :lower="currentLower"
            />
          </div>
        </div>
      </div>
      <!---===================================-->
      <!---===================================-->
      <div class="c-modal" v-show="showPreviewResultModal">
        <div class="c-modal-content">
          <div class="c-modal-header">
            <h3>{{ $t("Preview") }}</h3>
            <div class="x-icon" @click="closePreviewResultModal">
              <feather-icon size="16" icon="XIcon" />
            </div>
          </div>
          <div class="c-modal-body">
            <PreviewResultModel
              v-if="showPreviewResultModal"
              :results="results"
            />
          </div>
        </div>
      </div>
      <!---===================================-->
      <!---===================================-->
      <b-modal
        id="edit-tag-modal"
        v-model="showCreditModal"
        title="Not enough credits"
        centered
        size="md"
        hide-footer
      >
        {{ $t("You do not have enough credits to recalculate the case") }}
        <div class="d-flex align-items-center justify-content-end mt-2">
          <b-button @click="showCreditModal = false" variant="primary">{{
            $t("Ok")
          }}</b-button>
        </div>
      </b-modal>
      <!---===================================-->
      <!---===================================-->
    </div>
  </div>
</template>

<script>
import CookieButton from "@/components/elements/CookieButton.vue";
import ElementIcon from "@/components/elements/Icon.vue";
import { BLink, BRow, BCol, BImg, BPagination } from "bootstrap-vue";
import FileService from "@/services/file.service";
import { mapGetters } from "vuex";
import MultiSelect from "vue-multiselect";
import NProgress from "nprogress";
import PreviewModel from "@/components/PreviewModel.vue";
import PreviewResultModel from "@/components/PreviewResultModel.vue";
import PageHeader from "@/components/PageHeader.vue";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import StarRating from "@/components/elements/StarRating.vue";
import { debounce } from "@/utils/debounce";
export default {
  components: {
    ElementIcon,
    BRow,
    BCol,
    BLink,
    BImg,
    BPagination,
    MultiSelect,
    CookieButton,
    PreviewModel,
    PreviewResultModel,
    PageHeader,
    ValidationProvider,
    ValidationObserver,
    StarRating,
  },
  data() {
    return {
      selectedJaw: "both",
      showModal: false,
      showCreditModal: false,
      showPreviewModal: false,
      showPreviewResultModal: false,
      currentTag: {
        index: null,
        text: "",
        color: "#ffffff",
      },
      intervalId: null,
      previousItems: null,
      currentPage: 1,
      rows: [],
      selectedId: null,
      selectAll: false,
      totalRecords: 0,
      credits: 0,
      selectedCases: [],
      tag: {
        flag: null,
        tagColor: "",
      },
      colors: [
        { name: "Red", value: "#ff0000" },
        { name: "Green", value: "#00ff00" },
        { name: "Blue", value: "#0000ff" },
        { name: "Yellow", value: "#ffff00" },
        { name: "Magenta", value: "#ff00ff" },
        { name: "Cyan", value: "#00ffff" },
        { name: "Maroon", value: "#800000" },
        { name: "Dark Green", value: "#008000" },
        { name: "Navy", value: "#000080" },
        { name: "Gray", value: "#808080" },
        { name: "Orange", value: "#ffa500" },
        { name: "Purple", value: "#800080" },
        { name: "Teal", value: "#008080" },
        { name: "Olive", value: "#808000" },
        { name: "Silver", value: "#c0c0c0" },
        { name: "Gold", value: "#ffd700" },
        { name: "Coral", value: "#ff7f50" },
        { name: "Salmon", value: "#fa8072" },
        { name: "Lavender", value: "#e6e6fa" },
        { name: "Sky Blue", value: "#87ceeb" },
        { name: "Slate Gray", value: "#708090" },
        { name: "Chocolate", value: "#d2691e" },
        { name: "Crimson", value: "#dc143c" },
        { name: "Periwinkle", value: "#ccccff" },
        { name: "Mint", value: "#98ff98" },
        { name: "Peach", value: "#ffcc99" },
        { name: "Ivory", value: "#fffff0" },
        { name: "Plum", value: "#dda0dd" },
        { name: "Turquoise", value: "#40e0d0" },
        { name: "Beige", value: "#f5f5dc" },
        { name: "Orchid", value: "#da70d6" },
        { name: "Tomato", value: "#ff6347" },
      ],
      form: {
        sortBy: "createdAt",
        sortOrder: "desc",
        search: "",
        status: "all",
        selectedFilter: "all",
        perPage: 25,
      },
      filteredCases: [],
      currentTag: {
        color: "",
      },
      costsPerUpperJaw: "",
      costsPerLowerJaw: "",
      recalculationFactor: "",
      existingFlags: [],
      currentUpper: null,
      currentLower: null,
      results: null,
      filterOptions: [
        { value: "all", text: "All" },
        { value: "active", text: "Active" },
        { value: "finished", text: "Finished" },
        { value: "flagged", text: "Flagged" },
        { value: "trash", text: "Trash" },
      ],
      filterRatingValue: null,
    };
  },
  computed: {
    ...mapGetters("globalConfiguration", ["configuration"]),
    ...mapGetters(["showLoader"]),
    ...mapGetters("caseLists", ["reportCaseID"]),
    items() {
      return [
        {
          text: this.$t("Dental Twin"),
          to: "/home",
        },
        {
          text: this.$t("Cases"),
          active: true,
        },
      ];
    },
    columns() {
      return [
        {
          tdClass: "vgt-left-align",
          thClass: "vgt-left-align white-color",
          label: this.$t("Case Name"),
          field: "name",
        },

        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Upper Case"),
          field: "upperCase",
          sortable: false,
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Lower Case"),
          field: "lowerCase",
          sortable: false,
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Status"),
          field: "status",
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Connected Case"),
          field: "isconnected",
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Date"),
          field: "date",
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Flag"),
          field: "flag",
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Action"),
          field: "action",
          sortable: false,
        },
      ];
    },
    // filteredRows() {
    //   const filtered =
    //     this.form.status === "all"
    //       ? this.rows
    //       : this.rows.filter((row) => row.status === this.form.status);
    //   console.log("Filtered Rows:", filtered);
    //   return filtered;
    // },
  },
  created() {
    this.debouncedFetch = debounce(async (newValue, oldValue) => {
      try {
        await this.loadItems();
      } catch (e) {
        console.error(e);
      }
    }, 300);
  },
  methods: {
    async setFilterRatingValue(event) {
      this.filterRatingValue = event;
      await this.loadItems();
    },
    reportCase(reportedCase) {
      this.$store.commit("caseLists/reportCaseID", reportedCase);
      this.$router.push("/tickets/create");
    },
    async applyStatusFilter() {
      await this.$nextTick();
      await this.loadItems();
    },
    async setRatingValue(event, id, jawType) {
      await this.$store.dispatch("caseLists/saveRating", {
        caseId: id,
        ...(jawType === "upper"
          ? { upperCaseRating: event }
          : { lowerCaseRating: event }),
      });
    },
    async updateStatus(id, status) {
      const title =
        status === "trash"
          ? this.$t("Do you want to delete this record?")
          : this.$t("Do you want to update this record?");
      const subText =
        status === "trash"
          ? this.$t("Yes delete it!")
          : this.$t("Yes update it!");
      this.$swal({
        title: title,
        text: this.$t("You can't revert your action"),
        type: "warning",
        customClass: "custom-delete-popup",
        showCancelButton: true,
        confirmButtonText: subText,
        cancelButtonText: this.$t("No"),
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          await this.$store
            .dispatch("caseLists/updateStatus", {
              caseId: id,
              status: status,
            })
            .finally(() => {
              this.loadItems();
            });
        }
      });
    },
    handlePreviewClick(upper, lower) {
      this.currentUpper = upper;
      this.currentLower = lower;
      this.showPreviewModal = true;
    },
    handlePreviewResultClick(array) {
      this.results = array;
      this.showPreviewResultModal = true;
    },

    async getUpperImage(fileId, caseItem) {
      try {
        // Fetch the binary data as a Blob
        const response = await FileService.getResultById(fileId);

        // Create a FileReader to convert the Blob to Base64
        const reader = new FileReader();

        reader.onloadend = () => {
          // Get the Base64 string
          const base64data = reader.result;

          // Set the Base64 string to caseItem
          this.$set(caseItem, "upperImageSrc", base64data); // Use Vue's reactivity
        };

        // Read the Blob as a Data URL (Base64)
        reader.readAsDataURL(response.data);
      } catch (error) {
        console.error("Error fetching binary file:", error);
      }
    },
    async getLowerImage(fileId, caseItem) {
      try {
        // Fetch the binary data as a Blob
        const response = await FileService.getResultById(fileId);

        // Create a FileReader to convert the Blob to Base64
        const reader = new FileReader();

        reader.onloadend = () => {
          // Get the Base64 string
          const base64data = reader.result;

          // Set the Base64 string to caseItem
          this.$set(caseItem, "lowerImageSrc", base64data); // Use Vue's reactivity
        };

        // Read the Blob as a Data URL (Base64)
        reader.readAsDataURL(response.data);
      } catch (error) {
        console.error("Error fetching binary file:", error);
      }
    },
    dateFormatter(timeStamp) {
      // Convert to Date object
      const date = new Date(timeStamp);

      // Format the date (YYYY-MM-DD HH:mm:ss)
      const formattedDate = date.toISOString().split("T")[0];
      const formattedTime = date.toTimeString().split(" ")[0]; // Extract time in HH:mm:ss format

      // Combine date and time
      return `${formattedDate} ${formattedTime}`;
    },
    downloadFiles(array) {
      FileService.downloadFiles(array);
    },
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },
    hoverMessage(caseItem) {
      // Check if the caseStatus is 'failed' or 'unsupported' and if results JSON is not empty
      if (
        ["failed", "unsupported"].includes(caseItem.caseStatus) &&
        caseItem.results
      ) {
        return caseItem.results["message"];
      }
      return ""; // Return empty string if conditions are not met
    },

    onSortChange(params) {
      this.form.sortOrder = params[0].type;
      if (params[0].type == "none") this.form.sortOrder = "asc";
      this.form.sortBy = params[0].field;
      this.loadItems();
    },
    async loadFlags() {
      let response = await this.$store.dispatch("flags/list", {
        isAdminPortal: true,
      });
      this.existingFlags = response?.data?.data;
    },
    async updateCase() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          await this.$store
            .dispatch("caseLists/updateFlag", {
              caseId: this.selectedId,
              tagColor: this.tag.tagColor ?? "#ffffff",
              flag: this.tag.flag?.id ?? null,
            })
            .then((res) => {
              this.loadItems();
              this.showModal = false;
            });
        }
      });
    },

    async loadItems() {
      await this.$store
        .dispatch("caseLists/list", {
          page: this.page,
          ...this.form,
          isAdminPortal: true,
          selectedFilter: this.form.selectedFilter,
          starRating: this.filterRatingValue,
        })
        .then((res) => {
          this.$store.commit("showLoader", false);
          this.rows = res?.data?.data;
          this.totalRecords = res?.data?.meta?.total;
        })
        .finally(() => {
          this.$store.commit("showLoader", false);
        });
    },

    async addFlag(newTag) {
      await this.$store.dispatch("flags/create", {
        name: newTag,
        color: this.tag.tagColor,
      });
      this.loadFlags();
    },
    async approveCase(id, status) {
      const title =
        status === "approved"
          ? this.$t("Do you want to approve this record?")
          : this.$t("Do you want to reject this record?");
      const subText =
        status === "approved"
          ? this.$t("Yes approve it!")
          : this.$t("Yes reject it!");
      this.$swal({
        title: title,
        text: this.$t("You can't revert your action"),
        type: "warning",
        customClass: "custom-delete-popup",
        showCancelButton: true,
        confirmButtonText: subText,
        cancelButtonText: this.$t("No"),
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          await this.$store
            .dispatch("caseLists/approveRejectCase", {
              caseId: id,
              status: status,
            })
            .finally(() => {
              this.loadItems();
            });
        }
      });
    },
    async replicateCase(id) {
      this.$swal({
        title: this.$t("Do you want to recalculate this record?"),
        type: "warning",
        customClass: "custom-delete-popup",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes, recalculate it!"),
        cancelButtonText: this.$t("No"),
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          await this.$store
            .dispatch("caseLists/replicateCase", {
              caseId: id,
              extensions: [{ id: "opg-to-3d" }],
              isAdminPortal: true,
            })
            .finally(() => {
              this.getCredits();
              this.loadItems();
            });
        }
      });
    },
    async updateStatuses(status) {
      // Set title based on the status
      const title =
        status === "trash"
          ? this.$t("Do you want to delete these records?")
          : this.$t("Do you want to update these records?");
      const subText =
        status === "trash"
          ? this.$t("Yes delete them!")
          : this.$t("Yes update them!");
      this.$swal({
        title: title,
        text: this.$t("You can't revert your action"),
        type: "warning",
        customClass: "custom-delete-popup",
        showCancelButton: true,
        confirmButtonText: subText,
        cancelButtonText: this.$t("No"),
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          await this.$store
            .dispatch("caseLists/updateStatuses", {
              caseIds: this.selectedCases,
              status: status,
            })
            .finally(() => {
              this.selectedCases = [];
              this.loadItems();
            });
        }
      });
    },
    async approveCases(status) {
      // Set title based on the status
      const title =
        status === "approved"
          ? this.$t("Do you want to approve these records?")
          : this.$t("Do you want to reject these records?");
      const subText =
        status === "approved"
          ? this.$t("Yes approve them!")
          : this.$t("Yes reject them!");
      this.$swal({
        title: title,
        text: this.$t("You can't revert your action"),
        type: "warning",
        customClass: "custom-delete-popup",
        showCancelButton: true,
        confirmButtonText: subText,
        cancelButtonText: this.$t("No"),
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          await this.$store
            .dispatch("caseLists/approveStatuses", {
              caseIds: this.selectedCases,
              status: status,
            })
            .finally(() => {
              this.selectedCases = [];
              this.loadItems();
            });
        }
      });
    },
    async downloadCaseFiles() {
      this.$swal({
        title: this.$t("Do you want to download the selected case files?"),
        text: this.$t("You can't revert your action"),
        type: "warning",
        customClass: "custom-delete-popup",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes download them!"),
        cancelButtonText: this.$t("No"),
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          var cases = this.rows.filter((row) =>
            this.selectedCases.includes(row.id)
          );
          // Loop through each case using forEach
          cases.forEach((caseItem) => {
            this.downloadFiles([
              caseItem.files["upper"],
              caseItem.files["lower"],
            ]);
          });
          this.selectedCases = [];
        }
      });
    },
    openModal(caseItem) {
      this.selectedId = caseItem.id;
      this.tag.tagColor = caseItem.tagColor;
      this.tag.flag = caseItem.flag;
      this.showModal = true;
    },
    async getCredits() {
      const creditResponse = await this.$store.dispatch("customers/getCredits");
      this.credits = creditResponse?.data?.credits ?? 0;
    },
    toggleSelectAll(row) {
      if (this.selectAll) {
        // Map over rows to extract all IDs
        this.selectedCases = this.rows.map((row) => row.id);
      } else {
        this.selectedCases = [];
      }
    },
    closePreviewModal() {
      this.showPreviewModal = false;
    },
    closePreviewResultModal() {
      this.showPreviewResultModal = false;
    },
  },
  async mounted() {
    this.$store.commit("showLoader", true);
    this.loadItems();

    this.loadFlags();
  },
  beforeDestroy() {
    // Clear the interval when the component is destroyed
    clearInterval(this.intervalId);
  },
  watch: {
    selectedFilter(newValue) {
      console.log("Selected Filter:", newValue);
      this.loadItems();
    },
    selectedCases(newVal) {
      this.selectAll = newVal.length === this.rows.length;
    },
    "form.search": function (newVal, oldVal) {
      this.debouncedFetch();
    },
    "form.perPage": function (newVal, oldVal) {
      this.debouncedFetch();
    },
    "form.selectedFilter": function (newVal, oldVal) {
      this.debouncedFetch();
    },
  },
  beforeRouteEnter(to, from, next) {
    document.body.classList.add("cookie-layout");
    next();
  },
  beforeRouteLeave(to, from, next) {
    document.body.classList.remove("cookie-layout");
    next();
  },
};
</script>
<style lang="scss">
.form-dropdown {
  width: 100%;
  .dropdown-toggle {
    padding: 0 !important;
    height: auto !important;
    color: #fff;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &::after {
      display: none;
    }
  }
}
.hour-glass {
  img {
    width: 50px;
  }
}
.tag {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  border: 1px solid #0a1724;
}

.tag.flag {
  background: #f00;
  border-color: #f00;
}
.action {
  li {
    list-style: none;
    margin: 0;
    padding: 0;
    .dropdown-toggle {
      svg {
        width: 22px;
        height: 22px;
        path {
          fill: #fff;
          stroke: #fff;
        }
      }
      &::after {
        display: none;
      }
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}
.failed {
  border: 1px solid #f00;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  svg {
    width: 24px;
    height: 24px;
    path {
      stroke: #f00;
    }
  }
}
</style>
<style scoped>
.hidden {
  visibility: visible;
  display: block;
}
</style>
