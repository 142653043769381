<template>
  <div>
    <PageHeader :items="items" />
    <validation-observer ref="simpleRules">
      <div class="card">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-md-6">
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <TextInput
                  v-model="form.name"
                  :label="$t('Name')"
                  :tooltipMessage="$t('Name')"
                  :required="true"
                  :disabled="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-md-6">
              <validation-provider
                #default="{ errors }"
                name="Credits"
                rules="required"
              >
                <TextInput
                  v-model="form.credits"
                  type="number"
                  :disabled="true"
                  :label="$t('Credits')"
                  :required="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-6">
              <validation-provider
                #default="{ errors }"
                name="Costs"
                rules="required"
              >
                <TextInput
                  :disabled="true"
                  v-model="form.costs"
                  type="number"
                  :label="$t('Costs')"
                  :required="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div v-if="image.url" class="col-md-6 mb-2 d-flex flex-column">
              <label class="input-label form-label">{{ $t("Image") }} </label>
              <div class="news-image">
                <img :src="image.url" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </validation-observer>
    <div class="card mt-3">
      <div class="card-body">
        <div class="d-flex justify-content-between align-items-center">
          <h2>{{ $t("Country Packages") }}</h2>
        </div>
        <div
          class="d-flex justify-content-between align-items-center mb-1 mt-1"
        >
          <div class="custom-search d-flex justify-content-between">
            <b-form-group>
              <div class="d-flex align-items-center">
                <span class="text-nowrap"> {{ $t("Rows per page") }} </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['25', '50', '100']"
                  class="ml-1"
                  @input="(value) => onPerPageChange({ pageLength: value })"
                />
              </div>
            </b-form-group>
          </div>
          <div>
            <input
              autocomplete="off"
              type="text"
              name="search"
              class="form-control"
              :placeholder="$t('Search…')"
              v-model="search"
            />
          </div>
        </div>
        <div class="table-responsive api-keys-table">
          <!-- table -->
          <vue-good-table
            styleClass="vgt-table striped"
            :columns="columns"
            :fixed-header="false"
            :rows="rows"
            :search-options="{
              enabled: false,
              externalQuery: searchTerm,
              trigger: 'enter',
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
            mode="remote"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-per-page-change="onPerPageChange"
          >
            <template slot="table-row" slot-scope="props">
              <!-- Column: Name -->

              <span v-if="props.column.field === 'costs'">
                {{
                  $formatter(props.row.costs, $i18n.locale, "EUR", false, 2, 2)
                }}</span
              >
              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-end flex-wrap">
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="totalRecords"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value) => onPageChange({ currentPage: value })"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/TextInput.vue";
import PageHeader from "@/components/PageHeader.vue";
import MultiSelect from "vue-multiselect";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapGetters } from "vuex";
import countries from "@/assets/data/countries.json";
import { required, email } from "@validations";

export default {
  components: {
    TextInput,
    PageHeader,
    MultiSelect,
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    items() {
      return [
        {
          text: "Dental Twin",
          to: "/home",
        },
        {
          text: this.$t("Packages"),
          to: "/packages",
        },
        {
          text: this.$t("Show"),
          active: true,
        },
      ];
    },
    columns() {
      return [
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Country"),
          field: "country",
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Credits"),
          field: "credits",
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Costs"),
          field: "costs",
        },
      ];
    },
  },
  watch: {
    search(...val) {
      this.loadItems();
    },
  },
  data() {
    return {
      countries,
      form: {
        name: "",
        credits: "",
        costs: "",
      },
      costs: {
        country: "",
        credits: "",
        costs: "",
      },
      search: "",
      totalRecords: 0,
      pageLength: 25,
      page: 1,
      rows: [],
      searchTerm: "",
      sortBy: "createdAt",
      sortOrder: "desc",
      image: {},
    };
  },
  async mounted() {
    try {
      this.$store.commit("showLoader", true);
      await this.$store
        .dispatch("packages/show", this.$route.params.id)
        .then(async (res) => {
          var response = res?.data?.data;
          this.form.name = response?.name;
          this.form.credits = response?.credits;
          this.form.costs = response?.costs;
          this.image = response?.image ?? {};
        });
      await this.loadItems();
    } catch (e) {
      console.error("api error ___", e);
    } finally {
      this.$store.commit("showLoader", false);
    }
  },
  methods: {
    onSortChange(params) {
      this.sortOrder = params[0].type;
      if (params[0].type == "none") this.sortOrder = "asc";

      this.sortBy = params[0].field;
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ pageLength: params.pageLength });
      this.loadItems();
    },
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this, newProps);
    },

    // load items is what brings back the rows from server
    async loadItems() {
      let response = await this.$store.dispatch("countryPackages/list", {
        page: this.page,
        search: this.search,
        perPage: this.pageLength,
        sortBy: this.sortBy,
        sortOrder: this.sortOrder,
        packageId: this.$route.params.id,
      });
      this.rows = response?.data?.data;
      this.totalRecords = response?.data?.count;
    },
  },
};
</script>

<style lang="scss" scoped>
#nprogress {
  position: relative;
  z-index: 9999999;
}

.white-color {
  color: white !important;
}

.vgt-responsive {
  overflow-x: visible !important;
}

.news-image {
  margin-top: 10px;
  img {
    width: 200px;
    object-fit: contain;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 5px;
    border-radius: 5px;
  }
}
</style>
