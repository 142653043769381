var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group w-100",class:_vm.$attrs.class},[_c('label',{staticClass:"input-label form-label",attrs:{"for":'floating_standard_' + _vm.id}},[(_vm.required)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.label)+" ")]),(_vm.isLeftIcon || _vm.isRightIcon)?_c('div',{staticClass:"input-group"},[(_vm.isLeftIcon)?_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('ElementsIcon',{attrs:{"icon":_vm.leftIconName}})],1)]):_vm._e(),_c('input',_vm._b({ref:"input",staticClass:"input-form form-control",class:{ error: _vm.error },style:(_vm.isButton
          ? 'cursor: pointer;'
          : _vm.shouldHighlight
          ? 'border: 1px solid #2957a4;'
          : ''),attrs:{"id":_vm.id,"readonly":_vm.isReadonly,"type":_vm.type,"maxLength":_vm.maxLength,"placeholder":_vm.placeholder},domProps:{"value":_vm.value},on:{"input":_vm.handleInput,"keypress":_vm.onKeypress}},'input',Object.assign({}, _vm.$attrs, {class: null}),false)),(_vm.isRightIcon)?_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text"},[_c('ElementsIcon',{attrs:{"icon":_vm.rightIconName}})],1)]):_vm._e()]):_c('input',_vm._b({ref:"input",staticClass:"input-form form-control",class:{ error: _vm.error },style:(_vm.isButton
        ? 'cursor: pointer;'
        : _vm.shouldHighlight
        ? 'border: 1px solid #2957a4;'
        : ''),attrs:{"id":_vm.id,"readonly":_vm.isReadonly,"type":_vm.type,"maxLength":_vm.maxLength,"placeholder":_vm.placeholder},domProps:{"value":_vm.value},on:{"input":_vm.handleInput,"keypress":_vm.onKeypress}},'input',Object.assign({}, _vm.$attrs, {class: null}),false))])}
var staticRenderFns = []

export { render, staticRenderFns }